<template>
  <div class="dashboard-head">
    <div class="container">
      <div class="columns">
        <div class="column is-narrow is-flex is-aligned-middle">
          <div class="dashboard-head__avatar" @click="toggleSettingsModal(true)">
            <round-avatar
              :src="user.avatar.cropped"
              :acronym-fallback="user.first_name"
            />
          </div>
        </div>
        <div class="column">
          <div class="dashboard-head__user-name">
            {{ user.first_name }} {{ user.last_name }} <sup><i class="hf hf-comma" /></sup>
          </div>
          <div class="dashboard-head__user-meta">
            <div class="columns">
              <div class="column is-4">
                <ul class="dashboard-head__meta-list">
                  <DashboardHeadListItem
                    :has-value="!!user.metadata.company_name"
                    :value="user.metadata.company_name"
                    :placeholder="$t('components.dashboard_head.add_company')"
                    icon="hf-pencil"
                  />
                  <DashboardHeadListItem
                    :has-value="!!country"
                    :value="country && country.name"
                    :placeholder="$t('components.dashboard_head.add_country')"
                    icon="hf-globe"
                  />
                </ul>
              </div>
              <div class="column">
                <ul class="dashboard-head__meta-list">
                  <DashboardHeadListItem
                    :has-value="!!user.metadata.occupation"
                    :value="user.metadata.occupation && $t('pages.dashboard.occupation_types.' + user.metadata.occupation)"
                    :placeholder="$t('components.dashboard_head.add_occupation')"
                    icon="hf-user"
                  />
                  <DashboardHeadListItem
                    :has-value="!!user.email"
                    :value="user.email"
                    :placeholder="$t('components.dashboard_head.add_email')"
                    icon="hf-envelope"
                    type="mail"
                  />
                  <DashboardHeadListItem
                    :has-value="!!user.metadata.website"
                    :value="user.metadata.website"
                    :placeholder="$t('components.dashboard_head.add_website')"
                    icon="hf-desktop"
                    type="link"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="dashboard-head__edit has-text-weight-semibold" @click="toggleSettingsModal(true)">
        {{ $t('forms.edit') }}
      </a>
      <div class="has-text-right">
        <a
          :class="{'is-loading': isLoading }"
          class="button is-text no-underline button-request-user-info-dump"
          @click.prevent="requestUserInfoDump"
        >
          {{ $t('pages.dashboard.dump_user_info_button') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DashboardHeadListItem from './DashboardHeadListItem'

export default {
  components: {
    DashboardHeadListItem
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'dashboardUser'
    }),
    country () {
      return !this.$isEmpty(this.user.country) ? this.user.country : null
    }
  },
  methods: {
    ...mapActions({
      toggleSettingsModal: 'toggleDashboardSettingsModal'
    }),
    requestUserInfoDump () {
      this.$confirm(this.$t('pages.dashboard.confirmation_text'))
        .then(() => {
          this.isLoading = true
          this.$api.post('user/download-information')
            .then((response) => {
              this.isLoading = false
              this.$notify.success(this.$t({ message: this.$t('pages.dashboard.request_for_user_info_run'), duration: 10000 }))
            })
            .catch((error) => {
              this.isLoading = false
              this.$displayRequestError(error, this.$t('errors.generic'))
            })
        }).catch(() => { })
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.dashboard-head {
  padding: 1rem 0;
  background: linear-gradient(lighten($primary, 5%), $primary);
  color: $white;
  &:hover {
    .dashboard-head__edit {
      opacity: 1;
    }
  }
  &__avatar {
    cursor: pointer;
  }
  &__user-name {
    font-weight: $weight-semibold;
    font-family: $font-family-serif;
    font-size: $size-1;
  }
  &__user-meta {
    padding: 1rem 0;
  }
  &__meta-list {
    color: $grey-dark;
    font-size: $size-5;
    a {
      color: $grey-dark;
      &:hover {
        text-decoration: underline;
      }
    }
    li {
      margin-bottom: .5rem;
    }
    .dashboard-head__icon {
      color: $white;
      margin-right: .5rem;
      font-size: 1.2em;
      vertical-align: middle;
    }
  }
  &__edit {
    position: absolute;
    top: 0.75rem;
    right: 0;
    color: $white-bis;
    opacity: 0.5;
    font-size: 1.2rem;
    transition: 0.1s linear 0s
  }
}
</style>
