<template>
  <a
    :class="['is-'+type]"
    :href="link"
    :disabled="!handle"
    class="button"
    target="_blank"
  >
    <span class="icon">
      <i :class="['hf-'+type]" class="hf" />
    </span>
  </a>
</template>

<script>
export default {
  name: 'DashboardSidebarSocialsLink',
  props: {
    type: {
      type: String,
      required: true
    },
    handle: {
      type: String,
      default: ''
    }
  },
  computed: {
    link () {
      return this.handle
    }
  }
}
</script>
