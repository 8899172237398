<template>
  <div class="dashboard-sidebar-socials">
    <div class="dashboard-sidebar__content">
      <div class="social-share-buttons m-t-none">
        <DashboardSidebarSocialsLink
          :handle="user.metadata.facebook_profile"
          class="is-round"
          type="facebook"
        />
        <DashboardSidebarSocialsLink
          :handle="user.metadata.twitter_profile"
          class="is-round"
          type="twitter"
        />
        <DashboardSidebarSocialsLink
          :handle="user.metadata.linkedin_profile"
          class="is-round"
          type="linkedin"
        />
        <DashboardSidebarSocialsLink
          :handle="user.metadata.instagram_profile"
          class="is-round"
          type="instagram"
        />
        <DashboardSidebarSocialsLink
          :handle="user.metadata.pinterest_profile"
          class="is-round"
          type="pinterest"
        />
        <a
          :href="`mailto:${user.email}`"
          class="button is-dark is-round"
        >
          <span class="icon">
            <i class="hf hf-email" />
          </span>
        </a>
        <a
          class="button is-dark is-small"
          @click="toggleSocialsModal(true)"
        >
          {{ $t('forms.edit') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DashboardSidebarSocialsLink from './DashboardSidebarSocialsLink'

export default {
  components: {
    DashboardSidebarSocialsLink
  },
  computed: {
    ...mapGetters({
      user: 'dashboardUser'
    })
  },
  methods: {
    ...mapActions({
      toggleSocialsModal: 'toggleDashboardSocialsModal'
    })
  }
}
</script>
