<template>
  <li>
    <i :class="[icon]" class="hf hf-fw dashboard-head__icon" />
    <template v-if="hasValue">
      <template v-if="type === 'string'">
        {{ value }}
      </template>
      <a
        v-if="type === 'link'"
        :href="value"
        target="_blank"
      >{{ value }}</a>
      <a
        v-if="type === 'mail'"
        :href="`mailto:${value}`"
      >{{ value }}</a>
    </template>
    <template v-else>
      <a @click="toggleDashboardSettingsModal(true)">{{ placeholder }}</a>
    </template>
  </li>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    hasValue: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'string'
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions(['toggleDashboardSettingsModal'])
  }
}
</script>
