<template>
  <div class="dashboard-sidebar-followings">
    <div class="dashboard-sidebar__title">
      <h3>{{ $t('components.dashboard_sidebar.following') }}</h3>
      <a
        v-if="user.followed_newsrooms.length"
        class="dashboard-sidebar__modal-trigger has-text-weight-semibold"
        @click="isEditting = !isEditting"
      >
        {{ $t('forms.edit') }}
      </a>
    </div>
    <div class="dashboard-sidebar__content">
      <ul
        v-if="user.followed_newsrooms.length"
        v-loading="isLoading"
        class="is-size-5"
      >
        <li
          v-for="newsroom in user.followed_newsrooms"
          :key="newsroom.id"
          class="columns is-mobile is-aligned-middle"
        >
          <div class="column is-narrow">
            <round-avatar
              :main-color="newsroom.main_color"
              :src="newsroom.logo.cropped"
              :acronym-fallback="newsroom.name"
              size="small"
            />
          </div>
          <div class="column">
            {{ newsroom.name }}
          </div>
          <transition name="fade">
            <div v-if="isEditting" class="column is-narrow">
              <a @click="unfollow(newsroom)">
                <i class="hf hf-times" />
              </a>
            </div>
          </transition>
        </li>
      </ul>
      <div v-if="isEditting" class="has-text-right">
        <button class="button is-small" @click="isEditting = !isEditting">
          {{ $t('forms.done') }}
        </button>
      </div>
      <div v-if="!user.followed_newsrooms.length" class="has-text-centered">
        <p class="is-size-6 has-text-muted m-b-m">
          {{ $t('errors.no_followed_companies') }}
        </p>
        <p class="is-size-6 has-text-muted m-b-m">
          {{ $t('components.dashboard_sidebar.to_follow_company') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      isEditting: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'dashboardUser'
    })
  },
  methods: {
    ...mapActions([
      'fetchCurrentUser',
      'fetchDashboardUser'
    ]),
    async unfollow (newsroom) {
      try {
        await this.$confirm('Are you sure')
        this.isLoading = true
        await this.$api.post(`newsrooms/${newsroom.id}/unfollow`)
        await Promise.all([this.fetchCurrentUser(), this.fetchDashboardUser()])
        this.isLoading = false
        !this.user.followed_newsrooms.length && (this.isEditting = false)
      } catch (err) {
        this.isLoading = false
        this.$displayRequestError(err)
      }
    }
  }
}
</script>
