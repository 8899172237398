<template>
  <ul class="dashboardNavigation">
    <li
      v-for="nav in navigationItems"
      :key="nav.name"
    >
      <router-link :to="nav.to" active-class="is-active">
        {{ $t(nav.name) }}
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'DashboardNavigation',
  data () {
    return {
      navigationItems: [
        // { disabled: true, to: { name: 'dashboard.alerts' }, name: 'components.dashboard_navigation.alerts' },
        { to: { name: 'dashboard.suggested_stories' }, name: 'components.dashboard_navigation.suggested_stories' },
        { to: { name: 'dashboard.suggested_newsrooms' }, name: 'components.dashboard_navigation.suggested_companies' }
      ]
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.dashboardNavigation {
  display: flex;

  li {
    font-weight: $weight-semibold;
    padding: 1rem 1.5rem;
    text-transform: uppercase;
    font-size: $size-6;
    flex: 1 1 0;
    text-align: center;

    &:first-child {
      padding-left: 0;
    }

    .is-active {
      color: $primary;
    }
  }

  @include tablet {
    li {
      font-size: $size-4;
      flex: 0 0 auto;
      text-align: left;
    }
  }
}
</style>
