<template>
  <div class="dashboard-sidebar">
    <div id="dashboardSocials" class="dashboard-sidebar__section p-t-none">
      <dashboard-sidebar-socials />
    </div>
    <div id="dashboardInterests" class="dashboard-sidebar__section">
      <div class="dashboard-sidebar__title">
        <h3>{{ $t('components.dashboard_sidebar.interests') }}</h3>
        <a
          class="dashboard-sidebar__modal-trigger has-text-weight-semibold"
          @click="toggleInterestsModal(true)"
        >
          {{ $t('forms.edit') }}
        </a>
      </div>
      <div class="dashboard-sidebar__content">
        <div v-if="user.interests.length" class="is-size-5">
          <span v-for="(interest, index) in user.interests" :key="interest.id">
            {{ $t('categories.' + interest.id) }}
            <template v-if="index+1 < user.interests.length">,</template>
          </span>
        </div>
        <div v-if="!user.interests.length" class="has-text-centered">
          <p class="is-size-6 has-text-muted m-b-m">
            {{ $t('errors.no_interests') }}
          </p>
          <div>
            <button class="button is-outlined has-text-weight-normal" @click="toggleInterestsModal(true)">
              <span class="icon is-small">
                <i class="hf hf-plus" />
              </span>
              <span>{{ $t('components.dashboard_sidebar.add_interests') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="dashboardFollowing" class="dashboard-sidebar__section">
      <dashboard-sidebar-followings />
    </div>
    <div
      v-if="false"
      id="dashboardLatestSearch"
      class="dashboard-sidebar__section"
    >
      <div class="dashboard-sidebar__title">
        <h3>{{ $t('components.dashboard_sidebar.latest_search') }}</h3>
      </div>
      <div class="dashboard-sidebar__content">
        <ul v-if="user.latest_search.length" class="is-size-5">
          <li v-for="search in user.latest_search" :key="search.id">
            {{ search.name }}
          </li>
        </ul>
        <p v-if="!user.latest_search.length">
          {{ $t('errors.no_searches_saved') }}
        </p>
      </div>
    </div>
    <div id="dashboardBio" class="dashboard-sidebar__section">
      <div class="dashboard-sidebar__title">
        <h3>{{ $t('components.dashboard_sidebar.biography') }}</h3>
        <a class="dashboard-sidebar__modal-trigger has-text-weight-semibold" @click="toggleBioModal(true)">
          {{ $t('forms.edit') }}
        </a>
      </div>
      <div class="dashboard-sidebar__content">
        <p v-if="user.metadata.bio" key="bio">
          {{ user.metadata.bio }}
        </p>
        <div
          v-if="!user.metadata.bio"
          key="no_bio"
          class="has-text-centered"
        >
          <p class="is-size-6 has-text-muted m-b-m">
            {{ $t('errors.no_bio') }}
          </p>
          <div>
            <button class="button is-outlined has-text-weight-normal" @click="toggleBioModal(true)">
              <span class="icon is-small">
                <i class="hf hf-plus" />
              </span>
              <span>{{ $t('components.dashboard_sidebar.add_bio') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DashboardSidebarFollowings from './DashboardSidebarFollowings'
import DashboardSidebarSocials from './DashboardSidebarSocials'

export default {
  name: 'DashboardSidebar',
  components: {
    DashboardSidebarFollowings,
    DashboardSidebarSocials
  },
  computed: {
    ...mapGetters({
      user: 'dashboardUser'
    })
  },
  methods: {
    ...mapActions({
      toggleInterestsModal: 'toggleDashboardInterestsModal',
      toggleBioModal: 'toggleDashboardBioModal'
    })
  }
}
</script>
<style lang="scss">
@import "~utils";

.dashboard-sidebar {
  &__section {
    padding: 1rem 0;
  }
  &__title {
    //font-size: $size-3;
    border-bottom: 2px solid $grey-dark;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin-bottom: 0;
      padding-right: .5rem;
      line-height: 1;
    }
  }
  &__content {
    font-size: $size-5;
  }
  &__modal-trigger {
    color: $hf__text-muted;
  }
}
</style>
