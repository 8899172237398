<template>
  <div id="Dashboard">
    <div v-if="hydrated" class="dashboard">
      <dashboard-head />
      <div class="container">
        <div class="columns m-b-none">
          <div class="column is-offset-3 is-9 dashboard-navigation-column">
            <dashboard-navigation />
          </div>
        </div>
        <div class="columns is-mobile is-multiline dashboard-content-wrap">
          <div class="column is-12-mobile is-3-tablet is-order-2-mobile">
            <div class="dashboard-content">
              <dashboard-sidebar />
            </div>
          </div>
          <div class="column is-12-mobile is-9-tablet is-flex is-order-1-mobile">
            <div class="dashboard-content">
              <transition name="fade" mode="out-in">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DashboardSettingsModal v-if="dashboardSettingsModalVisible" />
    <DashboardInterestsModal v-if="dashboardInterestsModalVisible" />
    <DashboardBioModal v-if="dashboardBioModalVisible" />
    <DashboardSocialsModal v-if="dashboardSocialsModalVisible" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DashboardHead from '@/components/dashboard/DashboardHead'
import DashboardNavigation from '@/components/dashboard/DashboardNavigation'
import DashboardSidebar from '@/components/dashboard/DashboardSidebar'

const DashboardInterestsModal = () => import(/* webpackChunkName: "DashboardInterestsModal" */ '@/components/dashboard/DashboardInterestsModal.vue')
const DashboardBioModal = () => import(/* webpackChunkName: "DashboardBioModal" */ '@/components/dashboard/DashboardBioModal.vue')
const DashboardSettingsModal = () => import(/* webpackChunkName: "DashboardSettingsModal" */ '@/components/dashboard/DashboardSettingsModal.vue')
const DashboardSocialsModal = () => import(/* webpackChunkName: "DashboardSocialsModal" */ '@/components/dashboard/DashboardSocialsModal.vue')

export default {
  components: {
    DashboardHead,
    DashboardNavigation,
    DashboardSidebar,
    DashboardSettingsModal,
    DashboardInterestsModal,
    DashboardBioModal,
    DashboardSocialsModal
  },
  data () {
    return {
      hydrated: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'dashboardInterestsModalVisible',
      'dashboardBioModalVisible',
      'dashboardSocialsModalVisible',
      'dashboardSettingsModalVisible',
      'dashboardUserHasInterests'
    ])
  },
  mounted () {
    this.appLoading(true)
    this.fetchUser()
      .then(() => {
        this.appLoading(false)
        this.openInterestsModalIfNoInterests()
      })
  },
  methods: {
    ...mapActions(['toggleDashboardInterestsModal', 'fetchDashboardUser']),
    fetchUser () {
      this.isLoading = true
      return this.fetchDashboardUser()
        .then(response => {
          this.hydrated = true
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.user_data_could_not_be_fetched'))
        })
    },
    openInterestsModalIfNoInterests () {
      if (!this.dashboardUserHasInterests) {
        this.toggleDashboardInterestsModal()
      }
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.dashboard {
  &-navigation-column {
    margin-top: 2.5rem;
    padding-bottom: 0 !important;
  }
  &-content-wrap {

  }
  &-content {
    border-top: $hf__separator-border;
    padding-top: 2rem;
    display: flex;
    width: 100%;
    & > * {
      width: 100%;
    }
  }
}

@include tablet {
  .dashboard {
    &-content {
      padding-bottom: 2rem;
    }
  }
}
</style>
